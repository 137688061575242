// CORE
$inline-css:                    false !default;
$print-css:                     false !default;

$bp-small:                      calc((480 / 16) * 1em);
$bp-medium:                     calc((680 / 16) * 1em);
$bp-large:                      calc((1024 / 16) * 1em);

@mixin respond-to($media) {
  // inline css
  @if $inline-css {
    @if $media == base-inline-nomediaquery {
      @content;
    }
    @else if $media == base-inline {
      @media screen { @content; }
    }
    @else if $media == base-to-medium-inline {
      @media only screen and (max-width: #{$bp-medium}) { @content; }
    }
    @else if $media == small-inline {
      @media only screen and (min-width: #{$bp-small}) { @content; }
    }
    @else if $media == medium-inline {
      @media only screen and (min-width: #{$bp-medium}) { @content; }
    }
    @else if $media == large-inline {
      @media only screen and (min-width: #{$bp-large}) { @content; }
    }
  }
  // print css
  @else if $print-css {
    @if $media == print {
      @media print { @content; }
    }
  }
  // async-loaded css
  @else {
    @if $media == base-nomediaquery {
      @content;
    }
    @else if $media == base {
        @media screen { @content; }
    }
    @else if $media == small {
      @media only screen and (min-width: #{$bp-small}) { @content; }
    }
    @else if $media == medium {
      @media only screen and (min-width: #{$bp-medium}) { @content; }
    }
    @else if $media == large {
      @media only screen and (min-width: #{$bp-large}) { @content; }
    }
    @else if $media == hover {
      @media (hover: hover) { @content; }
    }
    @else if $media == pointer-fine {
      @media (pointer: fine) { @content; }
    }
  }
}
