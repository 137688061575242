// SHARE
@import "core";

@include respond-to(base-inline) {
  :where(.tc_share){display:flex;gap:var(--spacing-4);}
  :where(.tc_share__popover){}
  :where(.tc_share__popover__main){position:relative;padding-bottom:var(--spacing-32);width:98vw;max-width:var(--bp-small);background-color:var(--color-100);
    .tc_heading{padding:var(--spacing-16);}
    & > .tc_button{position:absolute;right:var(--spacing-8);top:var(--spacing-8);}
  }
  :where(.tc_share__popover__main__list){display:flex;gap:var(--spacing-8);list-style:none;color:var(--color-900);padding:0 var(--spacing-16);}
  :where(.tc_share__popover__main__list__item){flex:1;background-color:var(--color-light);}
  :where(.tc_share__popover__main__list__item__action){flex:1;display:flex;flex-direction:column;align-items:center;gap:var(--spacing-8);padding:calc(var(--spacing-4) + var(--spacing-4-step));color:currentColor;font-size:var(--font-12-14);white-space:nowrap;text-decoration:none;transition:background-color var(--transition-fast);
    &[type="button"]{background:transparent;border:0;cursor:pointer;}
    svg{width:var(--spacing-40);height:var(--spacing-40);}
    &:active{background-color:var(--color-300);}
  }
  :where(.tc_share__popover__main__copylink){display:flex;flex-direction:column;gap:var(--spacing-16);padding:var(--spacing-24) var(--spacing-16) 0;
    .tc_form__group{flex:1;}
    .tc_form__input[readonly]{background-color:var(--color-light);}
    .tc_button{align-self:center;
      &.tc_button--green{
        svg{display:none;}
        .tc_share__popover__main__copylink__action--success{display:block;}
      }
      &.tc_button--tv2red:not(.tc_button--green){
        svg{display:none;}
        .tc_share__popover__main__copylink__action--error{display:block;}
      }
    }
  }
  :where(.tc_share__popover__main__copylink__action--success,.tc_share__popover__main__copylink__action--error){display:none;}

  :where(.tc_share__popover)::backdrop{background-color:var(--color-backdrop);}

  @supports not selector(:popover-open) {
    .tc_share__popover{display:none;}
  }

  @media (scripting:none) {
    .tc_share__popover__main__copylink__action{display:none;}
  }
}

@include respond-to(medium-inline) {
  :where(.tc_share__popover__main__copylink){flex-direction:row;}
}

@include respond-to(hover) {
  :where(.tc_share__popover__main__list__item__action:not(:active)):hover{background-color:var(--color-200);}
}

@include respond-to(print) {
  .tc_share,.tc_share__popover__main__list{display:none;}
}
